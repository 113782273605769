const LowConnectionIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 40 30" fill="none">
        <path
            d="M20.57 0.856689C12.5306 0.856689 5.26249 4.11361 0 9.39324L20.57 29.9975L29.1408 21.4095V7.71336H39.2887C34.2319 3.44508 27.7009 0.856689 20.57 0.856689Z"
            fill="white"
        />
        <path d="M35.9977 24.855H32.5693V28.2834H35.9977V24.855Z" fill="white" />
        <path d="M35.9977 11.1417H32.5693V21.4267H35.9977V11.1417Z" fill="white" />
    </svg>
);

export default LowConnectionIcon;
