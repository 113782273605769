import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";

const ImageWithErrorLoad = ({ src, defaultSrc, ...props }) => {
    const [imageSrc, setImageSrc] = useState(src);

    const onError = () => setImageSrc(defaultSrc);

    useEffect(() => {
        src && setImageSrc(src);
    }, [src]);

    return <img {...props} src={imageSrc} onError={onError} alt={props.alt} />;
};

ImageWithErrorLoad.defaultProps = {
    src: "",
    alt: "",
    defaultSrc: ""
};

ImageWithErrorLoad.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    defaultSrc: PropTypes.string
};

export default memo(ImageWithErrorLoad);
