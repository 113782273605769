import { urlParams } from "../../helpers";
import { saveGameData } from "../reducers/gameDataSlice";
import { savePendingDealingEnd } from "../reducers/animationsSlice";

const handleCreatePendingStack = () => {
    let stack = [];

    return [
        // add
        value => (stack = [...stack, value]),
        // clear
        () => (stack = []),
        // call
        callback => {
            if (stack?.length) {
                for (let i = 0; i <= stack.length; i++) {
                    stack[i] && typeof stack[i] === "function" && stack[i]();
                }
            }
            stack = [];
            typeof callback === "function" && callback();
        },
        // call first
        () => {
            const firstCall = stack[0];
            typeof firstCall === "function" && firstCall();
            stack.shift();
        },
        // call last
        () => {
            const lastCall = stack[stack.length - 1];
            typeof lastCall === "function" && lastCall();
            stack.pop();
        },
        // get stack length
        () => stack.length
    ];
};

export const [
    addPendingStack,
    clearPendingStack,
    callPendingStack,
    callPendingStackFirst,
    callPendingStackLast,
    getPendingStackLength
] = handleCreatePendingStack();

export const [
    addFastStepStack,
    clearFastStepStack,
    callFastStepStack,
    callFastStepStackFirst,
    callFastStepStackLast,
    getFastStepStackLength
] = handleCreatePendingStack();

export const handleClearAllPendingStacks = () => {
    clearPendingStack();
    clearFastStepStack();
};

const animationMiddleware =
    ({ getState }) =>
    next =>
    action => {
        const state = getState();
        const { isHistory } = urlParams;

        const {
            gameDataState: { player, opponent },
            animationsState: { dealingAnimationCount, player: playerCardsAnimation, opponent: opponentCardsAnimation }
        } = state;

        if (isHistory) {
            return next(action);
        }

        switch (action.type) {
            case "gameDataState/saveGameDataStep": {
                next(action);
                const { move, newRound } = action.payload || {};
                const isCardMoved = typeof move?.cardId === "number";
                const pendingLength = getPendingStackLength();

                if (!isCardMoved && pendingLength && !newRound) {
                    setTimeout(callPendingStackFirst, 0);
                }

                break;
            }

            case "animationsState/decrementDealingAnimationCount": {
                next(action);

                if (dealingAnimationCount === 1) {
                    // set game is started after dealing and call pending steps when animation enabled
                    next(savePendingDealingEnd(false));
                    next(
                        saveGameData({
                            isGameStarted: true,
                            player: { ...player, cards: playerCardsAnimation || [] },
                            opponent: { ...opponent, cards: opponentCardsAnimation || [] }
                        })
                    );
                    setTimeout(callPendingStackFirst, 150);
                }
                break;
            }

            case "animationsState/decrementCardsAnimation": {
                next(action);
                if (
                    (playerCardsAnimation?.length === 1 && !opponentCardsAnimation?.length) ||
                    (opponentCardsAnimation?.length === 1 && !playerCardsAnimation?.length)
                ) {
                    // call pending steps when animation enabled
                    setTimeout(callPendingStackFirst, 0);
                }
                break;
            }

            /* falls through */

            default:
                next(action);
        }
    };

export default animationMiddleware;
