import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    player: [],
    opponent: [],
    pendingDealingEnd: false,
    dealingAnimationCount: 0
};

const slice = createSlice({
    name: "animationsState",
    initialState,
    reducers: {
        saveUsersCardsAnimation: (state, action) => ({
            ...state,
            ...action.payload
        }),
        savePendingDealingEnd: (state, action) => {
            state.pendingDealingEnd = action.payload;
        },
        decrementCardsAnimation: (state, action) => {
            const { userType, cardId } = action.payload;
            let result = [...state[userType]];
            if (cardId) {
                result = result.filter(id => id !== cardId);
            } else {
                result.shift();
            }
            state[userType] = result;
        },
        incrementDealingAnimationCount: state => {
            state.dealingAnimationCount++;
        },
        decrementDealingAnimationCount: state => {
            state.dealingAnimationCount = state.dealingAnimationCount > 0 ? state.dealingAnimationCount - 1 : 0;
        },
        resetAnimationsCount: () => initialState
    },
    extraReducers: builder => {
        builder.addMatcher(
            action => action.type === "animationsState/decrementDealingAnimationCount",
            state => {
                if (state.dealingAnimationCount === 0) {
                    state.player = [];
                    state.opponent = [];
                }
            }
        );
    }
});

export const {
    saveUsersCardsAnimation,
    decrementCardsAnimation,
    incrementDealingAnimationCount,
    decrementDealingAnimationCount,
    resetAnimationsCount,
    savePendingDealingEnd
} = slice.actions;

export default slice.reducer;
