import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    resources: {
        BaseStake: "Base Stake",
        Classic: "Classic",
        Double: "DOUBLE",
        GameID: "Game ID",
        LeaveTheGameConfirmationText: "If You leave the game, You will lose. Are You sure You want to leave the game?",
        Player1: "Player 1",
        Player2: "Player 2",
        Prize: "Prize",
        Score: "Score",
        Settings: "SETTINGS",
        SoundEffects: "Sound Effects",
        Tournament: "Tournament",
        WeakConnection: "YOUR CONNECTION IS TOO WEAK",
        You: "You",
        Me: "Me",
        Info: "info",
        Conventions: "Conventions",
        Incognito: "Incognito",
        Private: "Private",
        HiddenCards: "Hidden Cards",
        WithBlindCard: "With Blind Card",
        Attention: "Attention!",
        YourOpponents: "Your Opponent’s",
        ConnectionLost: "Connection lost",
        StayTuned: "Stay Tuned!",
        GameAutomaticallyResumeConnectionRestored: "the game will automatically resume when the connection is restored",
        Themes: "Themes",
        Animation: "Animation",
        Cancel: "Cancel",
        ChoosingTrump: "Choosing Trump...",
        PleaseWaitUntilOpponentJoin: "Please wait until the opponent will join the game, otherwise you will lose.",
        PleaseWaitUntilPlayersJoin: "Please wait until the players will join the game.",
        GuestMode: "Guest Mode",
        demo: "demo",
        real: "real",
        game: "game",
        HitMe: "Hit Me",
        Stand: "Stand",
        BlindCard: "Blind Card",
        BaseCurrentStake: "Base / Current Stake",
        MaxStake: "Max. Stake",
        Redouble: "REDOUBLE",
        Accept: "ACCEPT",
        StakeDoublingMessage: "Would you like to Double the Stake? Your final Stake after doubling",
        StakeDoublingRequestMessage: "Your opponent offers to double the stake. Otherwise you will lose."
    }
};

export const translationSlice = createSlice({
    name: "translationState",
    initialState,
    reducers: {
        saveTranslationLoading: (state, action) => {
            state.loading = action.payload;
        },
        saveTranslation: (state, action) => {
            state.resources = { ...state.resources, ...action.payload };
        }
    }
});

export const { saveTranslation, saveTranslationLoading } = translationSlice.actions;
export default translationSlice.reducer;
