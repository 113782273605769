const HitIcon = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 50 34" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.4792 4.94937C1.41226 5.23526 0.779099 6.33193 1.06498 7.39886L7.55899 31.6348C7.84487 32.7018 8.94155 33.3349 10.0085 33.049L27.2195 28.4373C28.2865 28.1515 28.9196 27.0548 28.6337 25.9879L22.1397 1.7519C21.8538 0.684969 20.7572 0.0518041 19.6902 0.337688L2.4792 4.94937ZM13.0014 21.2574C12.8637 22.0924 12.9361 23.0368 13.2184 24.0905L13.6476 25.6922L20.3494 23.8965L19.7847 21.789L15.38 22.9692L15.2953 22.6531C15.1561 22.1332 15.1456 21.6165 15.264 21.1029C15.3786 20.5753 15.6822 19.8539 16.1749 18.9389C16.9312 17.5466 17.4053 16.3655 17.5972 15.3955C17.8031 14.4217 17.7856 13.4853 17.5446 12.5861C17.2246 11.3918 16.6849 10.5577 15.9254 10.0835C15.1621 9.59536 14.2185 9.50186 13.0945 9.80303C11.9705 10.1042 11.2001 10.657 10.7832 11.4614C10.3625 12.2518 10.3121 13.244 10.6321 14.4383L11.0161 15.8714L13.2079 15.2841L12.7844 13.7035C12.5246 12.734 12.7951 12.142 13.596 11.9274C13.9894 11.822 14.3183 11.8694 14.5828 12.0696C14.8575 12.252 15.0796 12.6593 15.249 13.2915C15.4372 13.994 15.4493 14.7136 15.2853 15.4502C15.1353 16.1831 14.7542 17.121 14.1418 18.2639C13.5155 19.4105 13.1353 20.4084 13.0014 21.2574Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M40.4668 33.049L28.7347 29.9054L29.4328 29.7184C30.4997 29.4325 31.1329 28.3358 30.847 27.2689L26.1726 9.82393L28.3355 1.7519C28.6214 0.684971 29.7181 0.0518039 30.785 0.337688L47.996 4.94937C49.063 5.23526 49.6961 6.33193 49.4102 7.39886L42.9162 31.6348C42.6304 32.7017 41.5337 33.3349 40.4668 33.049ZM34.1554 12.4094L36.579 13.0588L33.5635 24.3127L35.8818 24.9338L39.8346 10.1815L38.2751 9.76365C37.9607 10.2065 37.6483 10.5293 37.3379 10.7323C37.0313 10.9212 36.6592 11.0172 36.2217 11.0205C35.7982 11.0274 35.2562 10.9425 34.5959 10.7655L34.1554 12.4094Z"
            />
        </svg>
    );
};

export default HitIcon;
