const SoundIcon = () => (
    <svg width="100%" height="100%" viewBox="0 0 24 24">
        <path
            d="M1212,103.2v6h4l5,5v-16l-5,5Zm13.5,3a4.344,4.344,0,0,0-2.5-4v8.1A4.846,4.846,0,0,0,1225.5,106.2Zm-2.5-8.8v2.1a6.989,6.989,0,0,1,0,13.4V115a9.031,9.031,0,0,0,0-17.6Z"
            transform="translate(-1209 -94.2)"
        />
    </svg>
);

export default SoundIcon;
