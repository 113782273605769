import { createSlice } from "@reduxjs/toolkit";

const initialState = { dataReceived: false, isStepApiCallPending: false };

export const dataReceivedSlice = createSlice({
    name: "dataReceivedState",
    initialState: { ...initialState },
    reducers: {
        saveStepApiCallPending: (state, action) => {
            state.isStepApiCallPending = action.payload;
        },
        saveGameDataReceivedSuccess: state => {
            state.dataReceived = true;
        },
        resetGameDataReceived: () => ({
            ...initialState
        })
    }
});

export const { saveGameDataReceivedSuccess, resetGameDataReceived, saveStepApiCallPending } = dataReceivedSlice.actions;
export default dataReceivedSlice.reducer;
