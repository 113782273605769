import { configureStore } from "@reduxjs/toolkit";
import animationMiddleware from "./middleware/animationMiddleware";

import historyState from "./reducers/historySlice";
import settingsState from "./reducers/settingsSlice";
import demoGameState from "./reducers/demoGameSlice";
import gameDataState from "./reducers/gameDataSlice";
import connectionState from "./reducers/connectionSlice";
import popupTypesState from "./reducers/popupTypesSlice";
import usersTimesState from "./reducers/usersTimesSlice";
import animationsState from "./reducers/animationsSlice";
import translationState from "./reducers/translationSlice";
import dataReceivedState from "./reducers/dataReceivedSlice";
import lowConnectionState from "./reducers/lowConnectionSlice";
import gameInitialState from "./reducers/gameInitialStateSlice";

export const store = configureStore({
    reducer: {
        historyState,
        settingsState,
        gameDataState,
        demoGameState,
        animationsState,
        usersTimesState,
        connectionState,
        popupTypesState,
        translationState,
        gameInitialState,
        dataReceivedState,
        lowConnectionState
    },
    devTools: process.env.NODE_ENV === "development",
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false
        }).concat(animationMiddleware)
});
