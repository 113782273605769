import { Provider } from "react-redux";
import { store } from "./core/store/store";
import { createRoot } from "react-dom/client";
import AppRoot from "./AppRoot";
import "./assets/styles/index.scss";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <AppRoot />
    </Provider>
);
