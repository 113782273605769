import { shallowEqual, useSelector } from "react-redux";
import AppConstants from "../../constants";
import { urlParams } from "../../helpers";

const { isHistory, gameId, bet, win } = urlParams;

const {
    themeCardTypes,
    network: {
        socketQueries: {
            doubleStakeResponse: { accept }
        }
    }
} = AppConstants;

export const useShallowSelector = selector => useSelector(selector, shallowEqual);

export const selectAppRootData = state => {
    const { gameDataState, gameInitialState } = state;

    return {
        isGameEnded: gameDataState?.finishStatus,
        gameConfigIsDemo: gameInitialState?.gameConfiguration?.isDemo && !isHistory
    };
};

export const selectAppData = state => {
    const { settingsState, translationState, gameInitialState, gameDataState, lowConnectionState } = state;
    const { loadingAfterVisible, loading } = gameDataState;
    const { gameConfiguration, tournamentInfo } = gameInitialState;
    const { isConnectionLow } = lowConnectionState;

    const isConfig = !!gameConfiguration;
    const { tournamentId } = tournamentInfo || {};
    const apiCallLoading = settingsState?.loading || translationState.loading;
    const appLoading = loadingAfterVisible || (loading && tournamentId);

    return {
        isConfig,
        appLoading,
        apiCallLoading,
        isConnectionLow
    };
};

export const selectCancelGameWaitingData = state => {
    const { gameInitialState, gameDataState, dataReceivedState } = state;
    const { isGuest, tournamentInfo } = gameInitialState ?? {};
    const { finishStatus, isGameStarted, loading } = gameDataState ?? {};

    return {
        isGuest,
        isGameStarted,
        isGameEnded: finishStatus,
        gameDataLoading: loading,
        dataReceived: dataReceivedState.dataReceived,
        tournamentId: tournamentInfo?.tournamentId
    };
};

export const selectConnectionLostData = state => {
    const gameInitialState = state.gameInitialState;

    return {
        initialState: gameInitialState,
        isGuest: gameInitialState?.isGuest
    };
};

export const selectGameContainerData = state => {
    const { settingsState, gameInitialState, gameDataState } = state;

    const {
        isGuest,
        gameConfiguration: { gameType, isDemo }
    } = gameInitialState;
    const { finishStatus } = gameDataState;
    const { isAnimationEnabled, currentTheme } = settingsState ?? {};

    const isGameEnded = !!finishStatus;

    return {
        isDemo,
        isGuest,
        isGameEnded,
        gameType,
        currentTheme,
        isAnimationEnabled
    };
};

export const selectTimerData = state => {
    const { usersTimesState: usersTimes, gameDataState, gameInitialState, animationsState } = state;
    const { finishStatus, isPlayerTurn, isPlayerPlayed, roundResult } = gameDataState ?? {};
    const { pendingDealingEnd } = animationsState;
    const {
        gameConfiguration: { gameTime, stepTime }
    } = gameInitialState;

    const activeUserType = isHistory
        ? typeof isPlayerPlayed === "boolean"
            ? isPlayerPlayed
                ? "player"
                : "opponent"
            : undefined
        : typeof isPlayerTurn === "boolean"
        ? !finishStatus && !pendingDealingEnd && isPlayerTurn
            ? "player"
            : "opponent"
        : undefined;

    return {
        stepTime,
        gameTime,
        usersTimes,
        isPlayerTurn,
        activeUserType,
        pendingDealingEnd,
        isHideTimer: !!roundResult,
        isGameEnded: !!finishStatus
    };
};

export const selectSettingsPopoverData = state => {
    const { soundEnabled, isAnimationEnabled, currentTheme, themes } = state.settingsState ?? {};

    return { soundEnabled, isAnimationEnabled, currentTheme, themes };
};

export const selectNotificationData = state => {
    const { connectionState, gameInitialState } = state;
    const { isGuest } = gameInitialState ?? {};
    const { player: playerConnection, opponent: opponentConnection } = connectionState;

    return {
        isGuest,
        playerConnectionLost: !playerConnection,
        opponentConnectionLost: !opponentConnection
    };
};

export const historyData = state => {
    const { historyState } = state;
    const { history, stepIndex, roundIndex, autoPlayEnabled } = historyState;

    return {
        rounds: history.rounds || [],
        stepIndex,
        roundIndex,
        autoPlayEnabled
    };
};

export const selectToggleSwitchDemoData = state => {
    const { demoGameState } = state;
    const { isSwitched, loading } = demoGameState;

    return {
        isSwitched,
        loading
    };
};

export const selectDealerDeckData = state => {
    const { gameInitialState, settingsState, animationsState } = state;

    const { isGuest } = gameInitialState ?? {};
    const { isAnimationEnabled } = settingsState || {};
    const { player: playerCardsAnimation, opponent: opponentCardsAnimation, pendingDealingEnd } = animationsState;

    const isDealingDeckActive = !isGuest && isAnimationEnabled;

    return {
        pendingDealingEnd,
        isDealingDeckActive,
        playerCardsAnimation,
        opponentCardsAnimation
    };
};

export const cardViewTypeSelector = state => {
    const { currentTheme } = state.settingsState;

    return themeCardTypes[currentTheme];
};

export const selectScoreBarWrapperData = state => {
    const { gameInitialState, gameDataState } = state;

    const { gameScore } = gameDataState;
    const { isGuest, gameConfiguration } = gameInitialState ?? {};
    const { isDemo } = gameConfiguration || {};

    return {
        gameScore,
        isGuest,
        isDemo
    };
};

export const selectScoreBarContentData = state => {
    const { gameInitialState, historyState } = state;

    const { autoPlayEnabled } = historyState;
    const { isGuest, gameConfiguration, playerInitialState, opponentInitialState } = gameInitialState ?? {};
    const { incognito } = gameConfiguration || {};

    return {
        isGuest,
        autoPlayEnabled,
        playerInitialState,
        opponentInitialState,
        isIncognito: incognito
    };
};

export const selectGameIdTimeWrapperData = state => {
    const { gameInitialState, historyState } = state;

    const { history } = historyState;
    const { gameConfiguration } = gameInitialState ?? {};
    const { isDemo } = gameConfiguration || {};

    return {
        history,
        isDemo
    };
};

export const selectSettingsContentData = state => {
    const { settingsState, animationsState, gameDataState } = state;

    const { roundResult } = gameDataState;
    const { themes, changeLoading, isAnimationEnabled, soundEnabled, currentTheme } = settingsState;
    const { player: playerAnimations, opponent: opponentAnimations, pendingDealingEnd } = animationsState;

    const isDisableInAnimation =
        !!playerAnimations?.length || !!opponentAnimations?.length || pendingDealingEnd || roundResult;

    return {
        themes,
        currentTheme,
        soundEnabled,
        changeLoading,
        isAnimationEnabled,
        isDisableInAnimation
    };
};

export const selectPlayerData = state => {
    const { gameDataState, connectionState, animationsState, gameInitialState } = state;

    const {
        isGuest,
        gameConfiguration: { isDemo }
    } = gameInitialState;
    const { player: isConnected } = connectionState;
    const { player: cardsWithAnimation } = animationsState;
    const { isPlayerTurn, player, isPlayerWin, isPlayerDealer, doubleStakeData } = gameDataState;
    const { cardsPoint: point, notification } = player || {};
    const definedPLayerWin = typeof isPlayerWin === "boolean";
    const { isPlayer, currentStake } = doubleStakeData || {};

    return {
        point,
        isDemo,
        notification,
        cardsWithAnimation,
        isUserTurn: isPlayerTurn,
        isUserDealer: isPlayerDealer,
        currentStake: isPlayer && currentStake,
        isUserWin: definedPLayerWin ? isPlayerWin : undefined,
        isConnectionLost: isConnected === false && (isGuest || isHistory),
        status: definedPLayerWin ? (isPlayerWin ? "win" : "lose") : "default"
    };
};

export const selectOpponentData = state => {
    const { gameDataState, connectionState, animationsState } = state;

    const { opponent: isConnected } = connectionState;
    const { opponent: cardsWithAnimation } = animationsState;
    const { isPlayerTurn, opponent, isPlayerWin, isPlayerDealer, doubleStakeData } = gameDataState;

    const { isPlayer, currentStake } = doubleStakeData || {};
    const { cardsPoint: point, notification } = opponent || {};
    const definedPLayerWin = typeof isPlayerWin === "boolean";

    return {
        point,
        notification,
        cardsWithAnimation,
        isUserTurn: !isPlayerTurn,
        isUserDealer: !isPlayerDealer,
        currentStake: !isPlayer && currentStake,
        isConnectionLost: isConnected === false,
        status: definedPLayerWin ? (!isPlayerWin ? "win" : "lose") : "default",
        isUserWin: typeof isPlayerWin === "boolean" ? !isPlayerWin : undefined
    };
};

export const selectCardsData = state => {
    const { gameDataState, animationsState } = state;

    const { dealingAnimationCount, pendingDealingEnd } = animationsState;
    const {
        player: { cards: playerCards },
        opponent: { cards: opponentCards }
    } = gameDataState;

    return {
        playerCards,
        opponentCards,
        pendingDealingEnd,
        dealingAnimationCount
    };
};

export const selectGameStepsData = state => {
    const { gameDataState, gameInitialState, animationsState, dataReceivedState } = state;

    const { gameConfiguration, isGuest } = gameInitialState ?? {};
    const {
        popups,
        isPlayerWin,
        roundResult,
        isPlayerTurn,
        isGoldenPoint,
        isPlayerDealer,
        doubleStakeData,
        player: { canPlayerOnlyStand }
    } = gameDataState ?? {};
    const { isStepApiCallPending } = dataReceivedState;

    const { doubleStake } = popups || {};
    const { status } = doubleStake || {};
    const { isMaxDoubleStake, isPlayer: isCanDouble, currentStake } = doubleStakeData || {};

    const { maxStakeDoubling, blindCard } = gameConfiguration ?? {};
    const { player: playerCardsAnimation, opponent: opponentCardsAnimation, pendingDealingEnd } = animationsState;

    const isRoundEnded = typeof isPlayerWin === "boolean";
    const isStepAnimation = !!playerCardsAnimation?.length || !!opponentCardsAnimation?.length;
    const isShowSteps = isPlayerTurn && !pendingDealingEnd && !isGuest && !isRoundEnded && !roundResult;
    const isShowBlindCard = !isPlayerDealer && blindCard;
    const isShowDoubleButton = maxStakeDoubling > 1;
    const isDoubleButtonDisabled = isMaxDoubleStake || !maxStakeDoubling || isCanDouble === false;

    return {
        isShowSteps,
        isStepAnimation,
        isShowBlindCard,
        isShowDoubleButton,
        canPlayerOnlyStand,
        isStepApiCallPending,
        isDoubleButtonDisabled,
        currentStake: currentStake || 1,
        isNotAnyAction: isGoldenPoint,
        showDoubleStackConfirmation: isPlayerTurn && status && status !== accept && !isGuest
    };
};

export const selectPopupWrapperData = state => {
    const { gameDataState, popupTypesState } = state;
    const { popups, doubleStakeData } = gameDataState;

    const isCanRedouble = popups?.doubleStake?.canRedouble;
    const { popupType, popupLoading } = popupTypesState;

    const currentStake = doubleStakeData?.currentStake || 1;

    const prizeAfterDouble = currentStake * win * 2;

    return {
        isCanRedouble,
        popupType,
        popupLoading,
        prizeAfterDouble
    };
};

export const selectGameInfoWrapperData = state => {
    const { gameDataState, gameInitialState } = state;
    const { doubleStakeData } = gameDataState;
    const { gameConfiguration } = gameInitialState;

    const { currentStake } = doubleStakeData || {};
    const stake = currentStake || 1;

    return { ...gameConfiguration, gameId, win: parseFloat(win), bet: parseFloat(bet), currentStake: stake };
};
