import { createSlice, createSelector } from "@reduxjs/toolkit";
import { urlParams } from "../../helpers";

const initialState = {
    player: {},
    opponent: {},
    loading: false,
    loadingAfterVisible: false
};

export const gameDataSlice = createSlice({
    name: "gameDataState",
    initialState: { ...initialState },
    reducers: {
        saveGameData: (state, action) => ({
            ...state,
            ...action.payload
        }),
        saveGameDataStep: (state, action) => ({
            ...state,
            ...action.payload
        }),
        saveNewRoundGameData: (state, action) => ({
            ...state,
            ...action.payload,
            roundResult: null
        }),
        saveGameDataLoading: (state, action) => {
            state.loading = action.payload;
        },
        saveLoadingAfterVisible: (state, action) => {
            state.loadingAfterVisible = action.payload;
        },
        resetGameDateState: state => ({
            ...initialState,
            isGameStarted: state.isGameStarted
        })
    },
    extraReducers: builder => {
        builder.addMatcher(
            action => action.type === "gameDataState/saveGameDataLoading",
            state => {
                state.loadingAfterVisible = false;
            }
        );
        builder.addMatcher(
            action => action.type === "animationsState/decrementCardsAnimation",
            state => {
                state.player.cards = state.player.actualCards;
                state.opponent.cards = state.opponent.actualCards;
            }
        );
    }
});

const { win } = urlParams || {};
const gameDataSelect = state => state.gameDataState;

export const selectPrizeWithDoubleStack = createSelector(
    gameDataSelect,
    gameDataState => (gameDataState.doubleStakeData?.currentStake || 1) * win
);

export const {
    saveGameData,
    resetGameDateState,
    saveGameDataLoading,
    saveLoadingAfterVisible,
    saveNewRoundGameData,
    saveGameDataStep
} = gameDataSlice.actions;
export default gameDataSlice.reducer;
