import { createSlice } from "@reduxjs/toolkit";
import boardImage1 from "../../../assets/images/bg-image1.png";
import { IS_IOS_CHROME } from "../../constants";

export const settingsInitialState = {
    loading: false,
    changeLoading: false,
    soundEnabled: false,
    autoPlayEnabled: false,
    isAnimationEnabled: !IS_IOS_CHROME,
    resRejected: undefined,
    resFulfilled: undefined,
    currentTheme: 1,
    backgroundUrl: boardImage1,
    themes: [
        {
            id: 1,
            name: "Classic"
        }
    ]
};

export const settingsSlice = createSlice({
    name: "settingsState",
    initialState: { ...settingsInitialState },
    reducers: {
        saveSettings: (state, action) => ({
            ...state,
            ...action.payload
        })
    }
});

export const { saveSettings } = settingsSlice.actions;
export default settingsSlice.reducer;
